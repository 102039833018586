<template>
    <div class="wrapper-employer-branding">

        <!-- Banner -->
        <section class="sec_employer sec_employer-1" :style="{
            backgroundImage: `url('${currentEmployer.backgroundImage}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
        }">
            <img class="banner-hero" :src="currentEmployer.urlBannerHero" :alt="currentEmployer.altBannerHero"
                style="position: absolute; top: 0; left: 0; width: 25%; height: 25%; object-fit: contain; padding: 20px;" />
            <div class="sec-inner">
                <div class="sec-inner-content"> 
                    <navbar-employer-branding :logo-employer="currentEmployer.logoNavbar"
                        :socials="currentEmployer.socials" />
                    <div class="content_description" v-if="currentEmployer.designModel == 3">
                        <p class="content_description_title" v-html="currentEmployer.bannerDescription" :style="{
                            color: currentEmployer.wordColor, fontSize: '40px', fontFamily: 'Poppins'
                        }"></p>
                        <p class="content_description_title" v-html="currentEmployer.bannerDescription2" :style="{
                            color: currentEmployer.wordColor, fontSize: '25px'
                        }"></p>
                        <button class="rounded-full content_description_button" :style="{
                            backgroundColor:
                                currentEmployer.primaryColorEmployer,
                            borderColor:
                                currentEmployer.borderColor, color: currentEmployer.borderColor
                        }" @click="goToJobs">
                            Ver vacantes
                        </button>
                    </div>
                </div>
            </div>
            <img class="logo-footer-right" :src="currentEmployer.urlBannerHero2" alt="logo" style="position: absolute; bottom: 50px; right: 5%; max-width: 70px; height: auto;" />
        </section>

        <!-- First Section -->
        
        <section id="about" class="sec_employer sec_employer-2">
            <div class="sec-inner-first" v-if="currentEmployer.designModel == 3">
                <div class="sec-inner-left">
                    <modal :name="modalName" v-show="modalVisible">
                        <div>
                            <video :src="videoSrc" controls autoplay width="100%" height="auto"></video>
                        </div>
                    </modal>
                </div>
                
                <div class="sec-inner-right col-center">
                    <div v-for="(paragraph, index) in currentEmployer.aboutUsText" :key="`paragraph-${index}`">
                        <p class="text_primary-grey sec-inner-right_text" v-html="paragraph" :style="{ color: '#000' }"></p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Second Section -->

        <section id="values" class="sec_employer sec_employer-4"
            v-if="currentEmployer.designModel == 3 && currentEmployer.values">
            <div class="sec-inner col-center">
                <div class="values-header">
                    <p class="title-section_blue values-header_title mb-5" style="color:#0a2341; font-size: 40px;">
                        Nuestro Propósito y Valores
                    </p>
                </div>
                <div class="values-content-wrapper">
                    <div v-for="(value, index) in currentEmployer.values" :key="index" class="values-content"
                        :style="{ backgroundColor: value.color, color: value.color === '#b2d9bf' ? 'black' : 'white' }">
                        <div class="icon" v-if="value.icon">
                            <img :src="value.icon" style="width: 90px; height: 80px"/>
                        </div>
                        <div style="margin-top: 10px" v-if=" value.title && value.description">
                            <h3>{{ value?.title }}</h3>
                            <p style="margin-top: 30px">{{ value?.description }}</p>   
                        </div>
                        <div v-else style="margin-top: 10px">
                            <p style="font-size: 24px;"> {{ value?.text }}</p>
                        </div> 
                        <div v-if="value.button">
                            <button class="rounded-full content_description_button" :style="{
                                backgroundColor:
                                    currentEmployer.primaryColorEmployer,
                                borderColor:
                                    currentEmployer.borderColor, color: currentEmployer.borderColor, padding: '10px', width: '315px', marginTop: '80px'
                                }" @click="goToJobs">
                                Ver vacantes
                            </button>
                        </div> 
                    </div>
                </div>
            </div>
        </section>

        <!-- Three Section -->

        <section id="about">
            <div class="sec-inner" v-if="currentEmployer.designModel == 3">
                <div class="mt-5">
                    <p class="title-section_blue benefits-header_title text-center" style="color:#0a2341; font-size: 40px">Descubre lo que tenemos para ti</p>
                </div>
                <div class="row-between">
                    <div class="sec-inner-right col-center">
                        <div v-for="(item, index) in currentEmployer.aboutUsText2" :key="`card-${index}`" class="row" :style="{ backgroundColor: item.color }">
                            <div class="col-md-6"  v-if="index % 2 == 0">
                                <img :src="item.image" class="card-img-top" :alt="item.title" />
                            </div>
                            <div class="col-md-6" style="padding: 50px; text-align: left;">
                                <div v-for="(content, cIndex) in item.content" :key="`content-${cIndex}`">
                                    <h5 class="card-title" :style="{ color: item.color !== '#b2d9bf' ? '#fff' : '#000'}">{{ content.title }}</h5>
                                    <p class="card-text" :style="{ color: item.color !== '#b2d9bf' ? '#fff' : '#000', marginBottom: '20px' }">{{ content.description }}</p>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="index % 2 !== 0">
                                <img :src="item.image" class="card-img-top" :alt="item.title" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Four Section -->

        <section id="benefits" class="sec_employer sec_employer-5" v-if="currentEmployer.designModel == 3">
            <div class="sec-inner col-start">
                <div class="benefits-header">
                    <p class="title-section_blue benefits-header_title text-center" style="color:#0a2341; font-size: 40px ">
                        Nuestro Beneficios
                    </p>
                    <p style="color: #000">Tu salud es nuestra preocupación. Nos ocupamos de tu bienestar físico, mental y emocional.</p>
                    <p style="color: #000">
                        Desde charlas hasta accesos a gimnasios. 
                    </p>
                </div>
                <div class="col-center">
                    <transition>
                        <div class="benefits-wrapper">
                            <div class="row">
                                <div class="col-md-3" v-for="(item, index) in currentEmployer.benefits"
                                    :key="`benefit-${index}`">
                                    <div class="benefititem" :style="{ backgroundColor: item.color }">
                                        <div class="benefit-item_icon mt-3">
                                            <img :src="item.icon" style="width: 45px; height: 36px;"/>
                                        </div>
                                        <div style="width: 208px; justify-content: center; margin-top: 8px">
                                            <p class="benefit-item_description"
                                                v-for="(description, dIndex) in item.description"
                                                :key="`description-${dIndex}`" :style="{ color: item.color !== '#b2d9bf' ? '#fff' : '#0a2341', marginTop: '10px', fontSize: '15px', padding: '5px' }">
                                                {{ description }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </section>

        <!-- Five Section -->

        <section id="jobs" class="sec_employer sec_employer-11" ref="jobsElement" v-if="currentEmployer.designModel == '3'">
            <div class="sec-inner col-center">
                <div class="jobs-header">
                    <p class="title-section_blue benefits-header_title text-center"  style="color:#0a2341; font-size: 40px; line-height: 60px;">
                        ¡Únete a nosotros y despega hacia un futuro lleno de oportunidades.
                    </p>
                </div>
                <div class="jobs-wrapper col-center">
                    <div class="bagjobs_filters">
                        <!-- <div class="row-between-end gapx-1">
                            <div class="form-group">
                                <label class="form-group__label"
                                    :style="{ color: currentEmployer.secondaryWordColor || 'black' }">Ubicación</label>
                                <v-select label="name" value="id" :options="currentEmployer.location"
                                    v-model="filterCountryJob" />
                            </div>
                            <div class="form-group">
                                <label class="form-group__label"
                                    :style="{ color: currentEmployer.secondaryWordColor || 'black' }">Departamento</label>
                                <v-select label="name" value="id" :options="currentEmployer.area"
                                    v-model="filterCategory" />
                            </div>
                        </div> -->
                    </div>
                    <div v-if="currentEmployer.jobs && currentEmployer.jobs.length > 0">
                        <list-jobs :jobs="currentEmployer.jobs" :dynamicColor="currentEmployer.primaryColorEmployer" />
                    </div>
                    <div v-else>
                        <p>No hay trabajos disponibles en este momento.</p>
                    </div>
                    <div v-show="loading" class="text-center font-weight-bold">
                        <!-- {{ $t("global.loading") }} -->
                    </div>
                    <button v-if="currentEmployer.jobs && currentEmployer.jobs.length >= 8"
                        class="btn btnh-primary rounded-full button-grey"
                        :style="{ color: currentEmployer.primaryColorEmployer }">
                        Ver más
                    </button>
                </div>
                <div class="card-blue col-center" :style="{
                    backgroundColor: '#0a2341',
                }">
                    <p class="text_primary-grey card-blue_title">
                        ¿No encontraste una vacante para tu perfil?
                    </p>
                    <p class="text_primary-grey card-blue_text">
                        Registra tus datos en nuestra base de datos para
                        mantenernos en contacto!
                    </p>
                    <!-- <a class="btn btnh-primary button-grey" :href="createCandidateLink" style="background-color: #0a2341; border: 1px solid #fff; color: #fff" >
                        Registrarme
                    </a> -->
                    <button class="rounded-full content_description_button" :style="{
                                backgroundColor:
                                    currentEmployer.primaryColorEmployer,
                                borderColor:
                                    currentEmployer.borderColor, color: currentEmployer.borderColor, padding: '10px', width: '315px', marginTop: '30px'
                                }" @click="goToJobs">
                                Registrarme
                            </button>
                </div>
            </div>
            <div v-if="currentEmployer.designModel == '3'"
                :style="{width: '100%', height: '107px', backgroundColor: currentEmployer.primaryColorEmployer}">
            </div>
        </section>

        <!-- Six Section -->

        <section id="recruitment-process" v-if="currentEmployer.process" style="background-color: #b2d9bf; padding: 90px 0 90px 0;">
            <div class="sec-inner col-center">
                <div class="card-white col-start-center">
                    <div class="col-center" style=" width: 80%; margin-left: auto; margin-right: auto">
                        <div class="recruitment-process-header">
                            <p class="title-section_blue benefits-header_title text-center" style="color: #0a2341; font-size:40px ">
                                ¿Cómo va a ser el proceso de selección?
                            </p>
                        </div>
                        <div class="recruitment-process-wrapper">
                            <div
                                class="recruitment-process-item"
                                v-for="(item, index) in currentEmployer.process"
                                :key="`process-${index}`"
                                :class="{ 'active': item.showInfo }"
                                @click="toggleInfo(index)"
                            >
                            <div class="recruitment-process-item-content">
                                <h3 class="recruitment-process-item_title">Paso {{ index + 1 }}</h3>
                                <p
                                    class="recruitment-process-item_description"
                                    v-show="item.showInfo"
                                >
                                {{ item.description }}
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Seven Section -->

        <section id="slider" class="mt-5 mb-5 d-flex justify-content-center align-items-center">
            <div class="sec-inner" v-if="currentEmployer.designModel == 3" style="width: 80%">
                <carousel :items-to-show="1">
                    <slide v-for="(slide, index) in currentEmployer.slides" :key="index">
                        <div class="row h-100">
                            <div class="col-md-6 d-flex align-items-center" style="height: 300px;">
                                <img :src="slide.image" alt="Slide Image" class="img-fluid" style="height: 100%; width: 100%;">
                            </div>
                            <div class="col-md-6 d-flex align-items-center" :style="{backgroundColor: slide.color, color:'#fff', marginLeft: '-15px'}">
                                <div style="padding: 0 15px;">
                                    <h5>{{ slide.content.title }}</h5>
                                    <p>{{ slide.content.description }}</p>
                                </div>
                            </div>
                        </div>
                    </slide>
                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
        </section>
        

       <!-- Eight Section -->     
     
        <section id="small-carousel" class="mt-6 mb-5">
            <p class="title-section_blue benefits-header_title text-center" style="color: #0a2341; font-size:40px">Imagínate trabajando con nosotros</p>
            <div class="container mt-5">
                <modal class="modal-content" :name="modalName" v-show="modalVisible" style="border-radius: 0;">
                    <div>
                        <video v-if="modalContentType === 'video'" ref="videoPlayer" :src="modalContentSrc" controls autoplay style="width: 100%; height: 100%; z-index: 999; object-fit: cover; border-radius: 0"></video>
                    </div>
                </modal>
                <carousel :items-to-show="4" :hide-dots="true">
                    <slide v-for="(slide, index) in currentEmployer.slides2" :key="index">
                        <template v-if="slide.type === 'image'">
                            <img :src="slide.src" alt="Slide Image" class="img-fluid" style="height: 100%; padding: 2px;">
                        </template>
                        <template v-else-if="slide.type === 'video'">
                            <div @click="openModal('video', slide.src)" class="video-container">
                                <img :src="slide.playIcon" alt="Play Icon" class="play-icon">
                                <video class="video-slide" :src="slide.src" poster="/assets/images/group_01.png"></video>
                            </div>
                        </template>
                    </slide>
                    <template #addons>
                        <navigation direction="left" />
                        <navigation direction="right" />
                    </template>
                </carousel>
            </div>
        </section>

        <!-- Nine Section -->

        <section id="social-media" class="sec_know">
            <div class="know">
                <h2 class="title-section_white">Conocenos aún más en nuestras redes sociales</h2>
                <div class="social-media-wrapper">
                    <div v-for="(social, index) in currentEmployer.socials2" :key="'social-' + index"
                        class="social-media-item">
                        <a :href="social.link" target="_blank">
                            <img :src="social.icon" />
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <!-- Ten section -->

        <section id="our-media" class="sec_our">
            <div class="our">
                <p class="title-section_blue benefits-header_title text-center" style="color: #0a2341; font-size: 40px">Nuestros reconocimientos</p>
                <div class="our-media-wrapper">
                    <div v-for="(recog, index) in currentEmployer.recognition" :key="'recognition-' + index"
                        class="our-media-item">
                        <a :href="recog.link" target="_blank">
                            <img :src="recog.image" :alt="recog.name" />
                        </a>
                    </div>
                </div>
            </div>
        </section>


       <!-- Ten Section --> 

       <section id="airports-media" class="sec_image"  style="background-image: url('/assets/images/group_05.png');">
            <div class="airports">
                <div class="airports-media-wrapper">
                    <div class="airports-media-item">
                        <a></a>
                    </div>
                </div>
            </div>
        </section>

        <!-- eleven Section -->

        <section id="airports-media" class="sec_airports">
            <div class="airports">
                <div class="airports-media-wrapper">
                    <div v-for="(airport, index) in currentEmployer.airports" :key="'airport-' + index"
                        class="airports-media-item">
                        <a :href="airport.link" target="_blank">
                            <img :src="airport.image" :alt="airport.name" />
                        </a>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
  
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'bootstrap/dist/css/bootstrap.css'

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            modalName: "video-modal",
            modalVisible : false,
            visible: false, 
            modalContentType: "",
            modalContentSrc: "",
            employerBrands: {
                cococo: {
                    employerName: "marca",
                    designModel: "3",
                    backgroundImage: "/assets/images/banner_02.png",
                    urlBannerHero: "/assets/images/airportLogo.png",
                    urlBannerHero2: "/assets/images/banner_01.png",
                    altBannerHero: "Aeropuertos Uruguay",
                    logoNavbar: "/images/employerBranding/logoCOCOCO.svg",
                    BannerColorEmployer: "#081e39",
                    bannerDescription: "Donde Tu Talento Despega",
                    bannerDescription2: "Formar parte de nuestro equipo no solo es un trabajo, </br> es una exoeriencia de desarrollo personal y profesional.",
                    wordColor: "white",
                    secondaryWordColor: "",
                    borderColor: 'white',
                    aboutUsTitle: "f",
                    aboutUsText: [
                        "Corporación América Airports, líder global en gestión de aeropuertos con presencia en 52 ubicaciones en 6 países, llegó a Uruguay en 2003 con la misión de 'Acercar Uruguay al Mundo con Pasión.",
                        "En nuestra operación en Uruguay, gestionamos 8 aeropuertos y Latin America Cargo City, una plataforma logística central para la carga aérea del país. Contamos con un equipo cercano de 500 colaboradores altamente calificados, comprometidos con una cultura ágil, innovadora y diversa.",
                        "Trabajar en la puerta de entrada al país es una responsabilidad que asumimos con orgullo y dedicación, compartiendo valores para dejar nuestra huella y construir un futuro mejor para todos."
                    ],
                    aboutUsText2: [
                        {
                            color: '#0a2341',
                            image: "/assets/images/group_01.png",
                            content: [
                                {
                                    title: "Crecimiento sin Límites",
                                    description: "En corporación América Uruguay, más del 80% de nuestras vacantes se cubren con talento interno, brindándote oportunidades para crecer profesionalmente, fomentando tu carrera y habilidades."
                                },
                                {
                                    title: "Apoyo al Desarrollo Profesional",
                                    description: "Nuestra plataforma 'Talent' te ofrece una serie de herramientas para tu desarrollo. Accede a cursos, talleres y materiales especializados que te ayudarán a alcanzar tus objetivos, Además disfrutarás de descuentos en programas académicos."
                                }
                            ]
                        },
                        {
                            color: '#b2d9bf',
                            image: "/assets/images/group_02.png",
                            content: [
                                {
                                    title: "Ambiente de colaboración",
                                    description: "Experimentarás una cultura donde la colaboración y el compañerismo son la norma. \nSerás parte de un equipo donde cada voz importa y donde trabajar juntos \nse convierte en una experiencia enriquecedora. \nAquí somos #oneteam: unidos en cada proyecto, celebración y desafío."
                                },
                                {
                                    title: "Bienestar y flexibilidad: Tu equilibrio es Nuestra Prioridad",
                                    description: "Ofrecemos programas de beneficios adaptados a tus necesidades y \nflexibilidad en el trabajo para que encuentres el equilibrio ideal entre tu vida \npersonal y profesional."
                                }
                            ]
                        },
                        {
                            color: '#f39000',
                            image: "/assets/images/group_03.png",
                            content: [
                                {
                                    title: "Innovación y desafíos constantes",
                                    description: "En nuestra empresa, la innovación impulsará tu crecimiento continuo. Te \nreunirás trimestralmente para inspirar nuevas ideas y trasnformar la \nexperiencia en nuestros aeropuertos.\nEstamos en constante expansión, lo que genera desafios diarios y la \nbúsqueda constante de nuevas oportunidades lo que te brindará la \nposibilidad de crecer a medida que crecemos como equipo."
                                },
                                {
                                    title: "Tú opinión cuenta",
                                    description: "participa en encuestas, reuniones abiertas y proyectos. Aquí, tu voz \ncontribuye a dar forma a nuestro equipo."
                                }
                            ]
                        },
                        {
                            color: '#b2d9bf',
                            image: "/assets/images/group_04.png",
                            content: [
                                {
                                    title: "Proyectos internacionales",
                                    description: "Nuestra red global brinda la oportunidad de trabajar con expertos no solo \nen Uruguay sino en diferentes países y enfrentar desafíos únicos."
                                },
                                {
                                    title: "Compromiso Social y Educativo",
                                    description: "Más que una empresa, somos una comunidad unida por la innovación y la responsabilidad ambiental. \nNos comprometemos en proyectos sostenibles, eficiencia energética y \npreservación ambiental. \nCon programas de becas y compromisos sociales, fusionamos la innovación con la responsabilidad ambiental para crear soluciones \navanzadas y marcar la diferencia como comunidad."
                                }
                            ]
                        }
                    ],
                    logoFooter: "/images/employerBranding/logoCOCOCOSecondary.png",
                    descriptionFooter: "Descubre tu Futuro en Grupo 3C <br/>(COCOCO): Más que un Trabajo, <br/>una Trayectoria de Éxito.",
                    primaryColorEmployer: "transparent",
                    secondaryColor: "#464B96",
                    colorBackground: "#EBEBF5",
                    imageSection1: "/public/image1.jpg",
                    imageSection2: "/images/employerBranding/imageCococo2.png",
                    corpUbication: "La Uruca, Ciudad Toyota (Costa Rica)",
                    socials: [
                        {
                            name: "linkedin",
                            link: "https://www.linkedin.com/company/cococo-grupo3c/",
                            icon: "linkedin"
                        },
                        {
                            name: "facebook",
                            link: "https://www.facebook.com/COCOCO",
                            icon: "facebook"
                        }
                    ],
                    highlightsText:
                        "En <strong>Grupo 3C (COCOCO)</strong>, estamos comprometidos no solo a ser líderes en tecnología, sino también a<br class='br-desktop' />crear un entorno laboral donde cada empleado se sienta inspirado, apoyado y valorado.",
                    highlights: [
                        {
                            icon: "lightbulb",
                            title: "Innovación sin Límites",
                            description: [
                                "Forma parte de una empresa con visión vanguardista y tecnológica, donde la innovación es la norma y el aprendizaje no tiene límites.",
                                "Desarrolla tus habilidades en un entorno que fomenta la creatividad y te desafía a superar tus propios límites.",
                            ],
                        },
                        {
                            icon: "emoji-smile",
                            title: "Estabilidad y Confianza",
                            description: [
                                "Disfruta de la estabilidad económica que ofrecemos, proporcionando tranquilidad y seguridad para ti y tu familia.",
                                "Construye tu carrera en una empresa consolidada, respaldada por valores sólidos y un enfoque a largo plazo.",
                            ],
                        },
                        {
                            icon: "star",
                            title: "Crecimiento Personal y <br />Profesional",
                            description: [
                                "Crece con nosotros a través de oportunidades continuas de desarrollo profesional y capacitación personalizada.",
                                "Participa en proyectos significativos que te permitan aplicar y expandir tus habilidades, asegurando un crecimiento constante.",
                            ],
                        },
                        {
                            icon: "people",
                            title: "Compromiso y <br />Trabajo en Equipo",
                            description: [
                                "Únete a un equipo que valora el compromiso, la comunicación abierta y la colaboración efectiva.",
                                "Experimenta un ambiente de trabajo donde todos tienen voz y donde se fomenta la creatividad a través del trabajo conjunto.",
                            ],
                        },
                        {
                            icon: "shield-check",
                            title: "Bienestar y Flexibilidad",
                            description: [
                                "Priorizamos tu bienestar ofreciendo un equilibrio entre trabajo y vida personal, con opciones de teletrabajo y horarios flexibles.",
                                "Disfruta de un entorno que se preocupa por tu salud y felicidad, creando un espacio donde puedes prosperar tanto personal como profesionalmente.",
                            ],
                        },
                        {
                            icon: "award",
                            title: "Reconocimiento y <br />Recompensas",
                            description: [
                                "Únete a un equipo que valora el compromiso, la comunicación abierta y la colaboración efectiva.",
                                "Experimenta un ambiente de trabajo donde todos tienen voz y donde se fomenta la creatividad a través del trabajo conjunto.",
                            ],
                        },
                        {
                            icon: "heart",
                            title: "Compañerismo y Cultura <br />Empresarial Única",
                            description: [
                                "Forma parte de una empresa con visión vanguardista y tecnológica, donde la innovación es la norma y el aprendizaje no tiene límites.",
                                "Desarrolla tus habilidades en un entorno que fomenta la creatividad y te desafía a superar tus propios límites.",
                            ],
                        },
                        {
                            icon: "magic",
                            title: "Impacta en el Mundo <br />Empresarial",
                            description: [
                                "Contribuye a soluciones empresariales innovadoras que marcan la diferencia en el panorama tecnológico.",
                                "Siente el orgull o de formar parte de una empresa que brinda soluciones de vanguardia a empresas en todo el país.",
                            ],
                        },
                    ],
                    values: [
                        {
                            color: '#0a2341',
                            icon: "/assets/images/value_01.png",
                            title: "Compromiso",
                            description:
                                "Asumimos la responsabilidad de contribuir al desarrollo del país, manteniendo los más altos entándares de seguridad y calida. Vivimos nuestro propósito diariamente como un equipo unido.",
                        },
                        {
                            color: '#f39000',
                            icon: "/assets/images/value_02.png",
                            title: "Integridad",
                            description:
                                "Nos comprometemos a ser coherentes entre lo que decimos y hacemos. Fomentamos un ambiente inclusivo, tratando a todas las personas con equidad.",
                        },
                        {
                            color: '#9c1a81',
                            icon: "/assets/images/value_03.png",
                            title: "Superación",
                            description:
                                "Disfrutamos asumiendo nuevos desafíos y buscamos la excelencia en cada tarea. Nos esforzamos por superar las expectativas, sorprender y agregar valor en cada paso que damos.",
                        },
                        {
                            color: '#b2d9bf',
                            icon: "/assets/images/value_04.png",
                            title: "Actitud positiva",
                            description:
                                "contagiamos optimismo y buena energía, compartiendo nuestra mejor versión. Extendemos una mano al que lo necesita y nos comunicamos desde el respeto y la honestidad.",
                        },
                        {
                            color: '#b2d9bf',
                            icon: "/assets/images/value_05.png",
                            title: "Compromiso",
                            description:
                                "Asumimos la responsabilidad de contribuir al desarrollo del país, manteniendo los más altos entándares de seguridad y calida. Vivimos nuestro propósito diariamente como un equipo unido.",
                        },
                        {
                            color: '#0a2341',
                            icon: "",
                            title: "",
                            text: "Si te identificas con nuestra misión y valores, explora nuestras oportunidades",
                            description:
                                "",
                            button: true
                        },
                    ],
                    location: [
                        { id: 1, name: "Ciudad 1" },
                        { id: 2, name: "Ciudad 2" },
                        { id: 3, name: "Ciudad 3" }
                    ],
                    area: [
                        { id: 1, name: "Departamento 1" },
                        { id: 2, name: "Departamento 2" },
                        { id: 3, name: "Departamento 3" }
                    ],
                    jobs: [
                        {
                            id: 1,
                            title: "Desarrollador Full Stack",
                            location: "Ciudad 1",
                            area: "Departamento 1",
                            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae nisi vitae arcu luctus dignissim.",
                            requirements: ["Experiencia en HTML, CSS, JavaScript", "Conocimientos en frameworks de desarrollo web"]
                        },
                        {
                            id: 2,
                            title: "Diseñador UX/UI",
                            location: "Ciudad 2",
                            area: "Departamento 2",
                            description: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
                            requirements: ["Experiencia en diseño de interfaces de usuario", "Conocimientos de herramientas de diseño"]
                        },
                        {
                            id: 3,
                            title: "Especialista en Marketing Digital",
                            location: "Ciudad 3",
                            area: "Departamento 3",
                            description: "Donec eget ipsum luctus, suscipit nisi ac, faucibus orci. Nam viverra justo sit amet metus.",
                            requirements: ["Experiencia en campañas de marketing en redes sociales", "Conocimientos en SEO y SEM"]
                        }
                    ],
                    slides: [
                        {
                            color: '#0a2341',
                            image: "/assets/images/slider1_01.png",
                            content: {
                                title: " 'Además de tener y haber tenido grandes mentores \nquienes me enseñaron, impulsarón y motivarón a más, \npude también poner sobre la mesa todo lo que soy, \naportando mis valores y viviendo cada día los valores \nde la compañiay mi pasión por la aviación.'' ",
                                description: "Nicolás Simonet, \nJefe de operaciones & experiencia del aeropuerto de \nCarrasco"
                            }
                        },
                        {
                            color: '#f39000',
                            image: "/assets/images/group_02.png",
                            content: {
                                title: "Apoyo al Desarrollo Profesional",
                                description: "Nuestra plataforma 'Talent' te ofrece una serie de herramientas para tu desarrollo. Accede a cursos, talleres y materiales especializados que te ayudarán a alcanzar tus objetivos, Además disfrutarás de descuentos en programas académicos."
                            }
                        },
                    ],
                    slides2: [
                        {
                            type: 'image',
                            src: "/assets/images/slider2_01.png",
                        },
                        {
                            type: 'image',
                            src: "/assets/images/slider2_02.png",
                        },
                        {
                            type: 'video',
                            src: '/assets/videos/video_01.mp4',  
                            playIcon: '/assets/images/play.png'  
                        },
                        {
                            type: 'image',
                            src: "/assets/images/group_02.png",
                        },
                    ],
                    isoCerfiedLogo:
                        "/images/employerBranding/isoCertifiedLogo.png",
                    departments: [
                        "Administración",
                        "Atención al cliente",
                        "Contabilidad",
                        "Taller de Servicio",
                        "Ventas Directas",
                        "Operaciones",
                        "Marketing",
                        "Recursos Humanos",
                        "Relaciones Públicas",
                    ],
                    corpValues: "",
                    benefits: [
                        {
                            color: '#b2d9bf',
                            icon: "/assets/images/benefits_01.png",
                            title: "Actividades Anuales",
                            description: [
                                "Cumpleaños",
                                "Día libre para celebrar tu cumpleaños."
                            ],
                        },
                        {
                            color: '#0a2341',
                            icon: "/assets/images/benefits_02.png",
                            title: "Reconocimiento",
                            description: [
                                "Maternidad",
                                "mes y medio de medio horario adicional.",
                            ],
                        },
                        {
                            color: '#b2d9bf',
                            icon: "/assets/images/benefits_03.png",
                            title: "Teletrabajo y modalidades hibridas",
                            description: [
                                "Red de gimnasios a precios bonificados para vos y tu familia.",
                            ],
                        },
                        {
                            color: '#9c1a81',
                            icon: "/assets/images/benefits_04.png",
                            title: "Oportunidades de desarrollo",
                            description: [
                                "Fruta en las instalaciones y renovación del carnet de salud in company sin costo.",
                            ],
                        },
                        {
                            color: '#b2d9bf',
                            icon: "/assets/images/benefits_05.png",
                            title: "U COCOCO",
                            description: [
                                "plataforma de descuentos a nivel nacional.",
                            ],
                        },
                        {
                            color: '#f39000',
                            icon: "/assets/images/benefits_06.png",
                            title: "Programa de Pasantias",
                            description: [
                                "Campeonato deportivo anual",
                            ],
                        },
                        {
                            color: '#b2d9bf',
                            icon: "/assets/images/benefits_07.png",
                            title: "Beneficios de cumpleaños",
                            description: [
                                "Consultas y charlas con especialistas y charlas sobre finanzas personales.",
                            ],
                        },
                        {
                            color: '#0a2341',
                            icon: "/assets/images/benefits_08.png",
                            title: "Descuentos en la tienda COCOCO",
                            description: [
                                "Más de 50 cursos y talleres online para tu crecimiento.",
                            ],
                        },
                        {
                            color: '#9c1a81',
                            icon: "/assets/images/benefits_09.png",
                            title: "Programa Voluntarios COCOCO",
                            description: [
                                "Convenios con universidades con descuentos.",
                            ],
                        },
                        {
                            color: '#b2d9bf',
                            icon: "/assets/images/benefits_10.png",
                            title: "Convenios con organizaciones",
                            description: [
                                "Posibilidad de cambio de empresa y localidad dentro del grupo en Uruguay ",
                            ],
                        },
                        {
                            color: '#0a2341',
                            icon: "/assets/images/benefits_11.png",
                            title: "Canasta",
                            description: [
                                "Canasta de fin de año. kit de estudios, regalos y celebraciones especiales",
                            ],
                        },
                        {
                            color: '#b2d9bf',
                            icon: "/assets/images/benefits_12.png",
                            title: "Canasta",
                            description: [
                                "Accede a afters, sorteos de entradas y disfruta de la fiesta de fin de año",
                            ],
                        },
                    ],
                    process: [
                        {
                            showInfo: false,
                            title: "Aplicación",
                            description:
                                "En un primer momento, aplicarás por los distintos medios que disponemos: LinkedIn, correo, Tecoloco, empleos.net, Harvie. Desde donde llegaremos a tener tu información.",
                        },
                        {
                            showInfo: false,
                            title: "Evaluación",
                            description:
                                "Se procederá a evaluar, si en un lapso de una semana no has sido contactado, es probable que hayas sido descartado del proceso. Si no procederíamos a entrar en contacto contigo y brindarte detalles de la posición y del proceso.",
                        },
                        {
                            showInfo: false,
                            title: "Envío de pruebas",
                            description:
                                "Procederemos a llamarte y explicarte el proceso, en donde tendrás que resolver de 2-5 pruebas (según la posición lo requiera), pero de manera general será Performia, que evalúa temas de personalidad y Harvie, para conocerte mejor por medio de una video entrevista. Si se considera necesario, se aplica una prueba de personalidad extra, de inglés o Excel.",
                        },
                        {
                            showInfo: false,
                            title: "Entrevista",
                            description:
                                "Una vez que tengamos la información, la evaluaremos junto a las gerencias respectivas y si decidimos continuar, agendaremos una entrevista, que puede ser directamente con el Hiring Manager, o inicialmente con el área de Recursos Humanos.",
                        },
                        {
                            showInfo: false,
                            title: "Referencia",
                            description:
                                "Una vez concluida la entrevista, evaluaremos tu postulación y si decidimos continuar empezaremos a validar referencias laborales. Existe la posibilidad de que te contactemos para que nos brindes el contacto de referencias, así como cartas de tiempo laborado o de recomendación que tengas.",
                        },
                        {
                            showInfo: false,
                            title: "Cierre del proceso",
                            description:
                                "Si se determina que eres la persona que empezara a formar parte del proceso, serás contactado de RH con el fin de brindarte la oferta laboral de la posición y gestionar todo el proceso de ingreso a la compañía.",
                        },
                    ],
                    customSectionData: [
                        {
                            image: "/imagen.jpg",
                            text: "Texto descripcion"
                        }
                    ],
                    socials2: [
                        {
                            name: "linkedin",
                            link: "https://www.linkedin.com",
                            icon: "/assets/images/social_linkedin.png"
                        },
                        {
                            name: "youtube",
                            link: "https://www.youtube.com",
                            icon: "/assets/images/social_youtube.png"
                        },
                        {
                            name: "instagram",
                            link: "https://www.instagram.com",
                            icon: "/assets/images/social_instagram.png"
                        },
                        {
                            name: "facebook",
                            link: "https://www.facebook.com",
                            icon: "/assets/images/social_facebook.png"
                        },
                        {
                            name: "x",
                            link: "https://www.twitter.com",
                            icon: "/assets/images/social_x.png"
                        },
                        {
                            name: "tik-tok",
                            link: "https://www.tiktok.com/",
                            icon: "/assets/images/social_tik-tok.png"
                        },
                    ],
                    recognition: [
                        {
                            name: "ACA",
                            image: '/assets/images/recognition_1.png'
                        },
                        {
                            name: "LSQA",
                            image: '/assets/images/recognition_2.png'
                        },
                        {
                            name: "nombre",
                            image: '/assets/images/recognition_3.png'
                        },
                        {
                            name: "ACI",
                            image: '/assets/images/recognition_4.png'
                        },
                        {
                            name: "ASQ",
                            image: '/assets/images/recognition_5.png'
                        }
                    ],
                    airports: [
                        {
                            name: "America_Airports",
                            image: '/assets/images/airport_1.png'
                        },
                        {
                            name: "nombre",
                            image: '/assets/images/airportLogo.png'
                        },
                        {
                            name: "nombre",
                            image: '/assets/images/airport_1.png'
                        }
                    ],
                    testimonials: [
                        {
                            name: "Walter carazo",
                            job: "Vendedor Corporativo",
                            video: "/images/employerBranding/testimonial1.png",
                        },
                        {
                            name: "Sofia Alvarado",
                            job: "Estratégica en mercadeo",
                            video: "/images/employerBranding/testimonial2.png",
                        },
                        {
                            name: "Jesse Pickman",
                            job: "Cocinero",
                            video: "/images/employerBranding/testimonial1.png",
                        },
                        {
                            name: "Bruce Wayne",
                            job: "Vengador nocturno",
                            video: "/images/employerBranding/testimonial2.png",
                        },
                    ],
                    faqs: [
                        {
                            title: "¿Cuál es el proceso de Reclutamiento?",
                            content:
                                "Nuestro proceso típicamente incluye la revisión de currículums, entrevistas telefónicas, entrevistas en persona, evaluaciones técnicas o pruebas prácticas y finalmente verificación de referencias. Estamos comprometidos a brindar a cada candidato una oportunidad justa y equitativa.",
                        },
                        {
                            title: "¿Cuánto dura el proceso?",
                            content:
                                "El tiempo puede variar, pero generalmente tratamos de completar el proceso dentro de 4 semanas. Sin embargo, la duración puede depender de la complejidad del rol y la disponibilidad de los participantes en el proceso.",
                        },
                        {
                            title: "¿Recibiré feedback del proceso?",
                            content:
                                "Sí, esto se da siempre que se haya empezado la segunda fase del proceso (envió de pruebas o generación de entrevistas). Nuestro objetivo es proporcionar retroalimentación constructiva a cada candidato. Después de la entrevista, te informaremos sobre el siguiente paso. .",
                        },
                        {
                            title: "¿Cuándo recibiré dicho feedback?",
                            content:
                                "En un máximo de 15 días tras haber resuelto las pruebas enviadas. ¿Qué pasa si me descartan?  Te agradecemos mucho el proceso, y te recordamos que tenemos una base de datos que constantemente se está nutriendo para futuros procesos. En caso de que no hayamos decidido continuar para una vacante en específico, no significa que descartamos la posibilidad de que en un futuro te unas a nosotros. Te instamos a seguir revisando la plataforma Harvie y seguir participando en los procesos de reclutamiento de COCOCO - CONICO.",
                        },
                        {
                            title: "¿Qué pasa si no he recibido respuesta?",
                            content:
                                "n tal caso te recomendamos un mensaje directo en horarios de 8:00 - 5:00 a Raquel Campos al número (506) 85304147.",
                        },
                        {
                            title: "¿Cómo es el equipo con el cual estaré trabajando y cuál es la estructura organizativa?",
                            content:
                                "El equipo está formado por profesionales altamente cualificados en distintas profesiones y áreas. La estructura organizativa fomenta la comunicación abierta y la colaboración en todos los niveles. Si quisieras obtener más información puedes comunicarte por medio de correo a <a href='mailto:rrhh@cococo.cr'>rrhh@cococo.cr</a>. Te podemos remitir a personal que trabaja en la organización en el área al cual estás aplicando para que sepas más sobre la posición, tu rol y tu equipo de trabajo.",
                        },
                        {
                            title: "¿A quién puedo acudir si tengo alguna duda o pregunta sobre el proceso de reclutamiento?",
                            content:
                                "A personal de Recursos Humanos por medio del correo: <a href='mailto:rrhh@cococo.cr'>rrhh@cococo.cr</a>, así como al correo de Raquel Campos, asistente de RH que sería <a href='mailto:raquel.campos@cococo.cr'>raquel.campos@cococo.cr</a>. Puedes llamarnos al número 85304147 o  2202-8900 ext 8963.",
                        },
                        {
                            title: "¿Cuáles tipos de pruebas se aplican?",
                            content:
                                "Son 2, pero se pueden extender hasta 5 si la posición lo requiere. De manera general se aplica performia, para evaluar temas de personalidad, y Harvie con el fin de tener un primer acercamiento y reconocer al candidato. Si se considera necesario se aplica otra prueba de personal llamada Kudert, pruebas de Excel y/o pruebas del nivel de Inglés..",
                        },
                    ],
                    galleryBanner: [
                        "/images/employerBranding/galleryCertified1.png",
                        "/images/employerBranding/galleryCertified2.png",
                        "/images/employerBranding/galleryCertified3.png",
                        "/images/employerBranding/galleryCertified4.png",
                        "/images/employerBranding/galleryCertified5.png",
                    ],
                    videoTestimonial: "",
                    captionVideo: "",
                    videoTitles: "",
                    videoDescriptions: "",
                }
            }
        }

    },
    methods: {
        formatKey(key) {
            return key.split("-").join("").toLowerCase();
        },
        goToJobs() {
            var elemento = this.$refs.jobsElement;
            if (elemento) {
                var top = elemento.getBoundingClientRect().y;
                window.scrollTo({
                    top: top,
                    behavior: "smooth",
                });
            } else {
                console.error("Elemento con ref 'jobsElement' no encontrado.");
            }
        },
        toggleInfo(index) {
            this.currentEmployer.process[index].showInfo = !this.currentEmployer.process[index]
                .showInfo;
        },
        show() {
            this.visible = true;
        },  
        hide() {
            this.visible = false;
        },
        openModal(contentType, contentSrc) {
            this.modalContentType = contentType;
            this.modalContentSrc = contentSrc;
            this.modalVisible = true;

            if (contentType === "video" && this.$refs.videoPlayer) {
                this.$nextTick(() => {
                    if (typeof this.$refs.videoPlayer.play === 'function') {
                        this.$refs.videoPlayer.play();
                    } else {
                        console.error("El método play no está disponible en $refs.videoPlayer");
                    }
                });
            }
        },
        closeModal() {
            if (this.modalContentType === "video" && this.$refs.videoPlayer) {
                this.$refs.videoPlayer.pause();
                console.log('close');
                this.modalVisible = false;
            }
        },
    },
    
    computed: {
        currentEmployer() {
            const organizationKey = 'cococo';
            const formattedKey = this.formatKey(organizationKey);

            if (this.employerBrands[formattedKey]) {
                return this.employerBrands[formattedKey];
            } else {
                return null;
            }
        }
    },
};
</script>
<style lang="scss">

@import "@/styles/styles.scss";
@import "../assets/styles.css";

.sec-inner-first {
    display: flex;
    justify-content: space-between;
}

.sec-inner-left { 
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.sec-inner-right {
    flex: 1;
}

video {
    width: 500px; 
    height: auto;
    max-width: 100%; 
    max-height: 100%;
    border-radius: 18px;
}

.video-slide {
    border-radius: 0;
    width: 100%;
    height: 100%;
}

.sec-inner.custom-section-grid {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 20px;
}

.arrow-left,
.arrow-right {
    display: flex;
    align-items: center;
}

.custom-section-center {
    grid-column: 2;
}

.custom-section-card {
    display: flex;
}

.social-media-wrapper {
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    }

.social-media-item {
    background-color: #b2d9bf;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center; 
    align-items: center;
    margin: 5px;
    }
    
.social-media-item img {
    width: 80%;
    height: auto; 
}
.sec_know{
    display: flex;
    justify-content: center;
    }

.know {
    background-color: #0a2341;
    width: 80%;
    color: #fff;
    padding: 40px;
    border-radius: 20px;
    }

.sec_our {
    background-color: #eeeeee;
    margin-top: 50px;

}

.our-media-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
}

.our-media-item {
    margin: 0 20px;
    width: 100px;
    height: 100px;
    display: flex; 
    justify-content: center; 
    align-items: center; 

}
.our-media-item img {
    max-width: 100%; 
    max-height: 100%;
}
.sec_airports {
    background-color:#0a2341;

}

.airports-media-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
}

.airports-media-item {
    margin: 0 5px;
    width: 100px;
    height: 100px;
    display: flex; 
    justify-content: center; 
    align-items: center; 

}
.airports-media-item img {
    max-width: 60%; 
    max-height: 60%;
}
.recruitment-process-wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr)); 
    gap: 20px; 
    justify-content: center; 
}

.recruitment-process-item {
    background-color: #0a2341;
    border-radius: 10px; 
    box-shadow: 0 4px 6px #000; 
    cursor: pointer;
    height: 497px;
    color: #fff;
    justify-content: center;
}

.recruitment-process-item-content {
    padding: 20px; 
    text-align: center;
}
.recruitment-process-item.active {
  background-color: #fff; 
  color: #0a2341;
}

.recruitment-process-item_title {
    margin-bottom: 10px; 
}

.sec_image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.vm--modal {
  width: 60% !important;
  height: auto !important;
  margin-left: -10% !important;
}

.video-container {
    position: relative;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    cursor: pointer;
}

.modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
    width: 50vw; 
    background-color: white;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


@media only screen and (max-width: 574px){
  .vm--modal {
  width: 100% !important;
  margin-left: 40% !important;
}
}

@media screen and (max-width: 768px) {
    .recruitment-process-wrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
    }
}

@media screen and (max-width: 480px) {
    .recruitment-process-wrapper {
        grid-template-columns: minmax(0, 1fr);
    }
}
@media screen and (max-width: 768px) {
    .sec-inner-first {
        flex-direction: column;
    }

    .sec-inner-left,
    .sec-inner-right {
        width: 100%; 
    }

    video {
        width: 100%; 
        height: auto; 
        max-width: none; 
        border-radius: 18px; 
        margin-top: 50px;
    }
}

</style>

  